

.faucet-page .claim-status {
  margin-top: 16px;
}

.faucet-page .result-sharing {
  margin-top: 12px;
}

.faucet-page .result-sharing .sh-link > a {
  position: relative;
  margin-left: 2px;
  margin-right: 2px;
  height: 20px;
  box-sizing: border-box;
  padding: 1px 10px 1px 10px;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  outline: 0;
  text-decoration: none;
}

.faucet-page .result-sharing .sh-link > a > i {
  position: relative;
  display: inline-block;
  background: transparent 0 0 no-repeat;
}

.faucet-page .result-sharing .sh-link > a > span {
  font-size: 13px;
  line-height: 26px;
  margin-left: 4px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
}


.faucet-page .result-sharing .sh-link.sh-tw > a {
  background-color: #1d9bf0;
  color: #fff;
}

.faucet-page .result-sharing .sh-link.sh-tw > a > i {
  top: 4px;
  height: 18px;
  width: 18px;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 72 72'%3E%3Cpath fill='none' d='M0 0h72v72H0z'/%3E%3Cpath class='icon' fill='%23fff' d='M68.812 15.14c-2.348 1.04-4.87 1.744-7.52 2.06 2.704-1.62 4.78-4.186 5.757-7.243-2.53 1.5-5.33 2.592-8.314 3.176C56.35 10.59 52.948 9 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396c-1.125 1.936-1.77 4.184-1.77 6.58 0 4.543 2.312 8.552 5.824 10.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09-4.482 3.51-10.13 5.605-16.26 5.605-1.055 0-2.096-.06-3.122-.184 5.794 3.717 12.676 5.882 20.067 5.882 24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693 2.558-1.847 4.778-4.15 6.532-6.774z'/%3E%3C/svg%3E");
}


.faucet-page .result-sharing .sh-link.sh-md > a {
  background-color: #4d5160;
  color: #fff;
}

.faucet-page .result-sharing .sh-link.sh-md > a > i {
  top: 4px;
  height: 18px;
  width: 18px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 216.4 232'%3E%3Cpath fill='%232b90d9' d='M212 139c-3 16-29 34-58 38-15 2-30 3-46 3-26-2-46-7-46-7v8c4 25 26 27 47 28 21 0 39-6 39-6l1 19s-14 8-41 10c-14 1-32-1-53-6C9 214 1 165 0 116V76c0-50 33-65 33-65C50 3 78 0 108 0h1c29 0 58 3 74 11 0 0 33 15 33 65 0 0 1 37-4 63' /%3E%3Cpath fill='%23fff' d='M178 80v61h-25V82c0-13-5-19-15-19-12 0-18 8-18 22v33H96V85c0-14-6-22-17-22s-16 6-16 19v59H39V80c0-12 3-22 9-30 7-7 16-11 26-11 13 0 22 5 28 15l6 10 6-10c6-10 16-15 28-15 11 0 19 4 26 11 6 8 10 18 10 30' /%3E%3C/svg%3E");
}

