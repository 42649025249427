

#ipinfo-tooltip .tooltip-inner {
  max-width: none;
}

#ipinfo-tooltip .ipaddr-info {
  min-width: 200px;
  text-align: left;
}

#ipinfo-tooltip .ipaddr-info.claim-error {
  max-width: 500px;
}

#ipinfo-tooltip .ipaddr-info > table {
  width: 100%;
}

#ipinfo-tooltip .ipaddr-info .ipinfo-title {
  min-width: 80px;
}

#ipinfo-tooltip .ipaddr-info .ipinfo-value {
  min-width: 50px;
}

.faucet-page .status-sessions td, .pow-faucet .status-sessions th {
  padding: 0 12px;
}

.faucet-page .status-general {
  max-width: none;
}

.faucet-page .status-general .status-title {
  display: inline-block;
  width: 180px;
}
